<template>
  <Contacts type="groups"/>
</template>

<script>
import Contacts from "@/components/Contacts.vue";
export default {
  data() {
    return {};
  },
  components: {
    Contacts,
  },
};
</script>
